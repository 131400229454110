<template>
  <ConfirmDialog group="headless" :draggable="false">
    <template #message="slotProps">
      <template v-if="!slotProps.message.header && slotProps.message.message">
        <p class="p-confirm-dialog-message">{{ slotProps.message.message }}</p>
      </template>
      <template v-else>
        <div class="flex flex-col items-center">
          <p class="p-confirm-dialog-message">{{ slotProps.message.header }}</p>
          <p class="p-confirm-dialog-message mt-5">{{ slotProps.message.message }}</p>
        </div>
      </template>
    </template>
  </ConfirmDialog>
</template>
